import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const Header = () => {
  const { user, logoutUser } = useContext(AuthContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const toggleProductsDropdown = () => {
    setIsProductsDropdownOpen(!isProductsDropdownOpen);
  };

  const closeProductsDropdown = () => {
    setIsProductsDropdownOpen(false);
  };

  return user && user['policy_check'] ? (
    <div className='app-header'>
      <div className='header-content'>
        <div className='left-section'>
          <div className='menu-icon' onClick={toggleSidebar}>
            &#9776; {/* Biểu tượng menu */}
          </div>
          <nav className='nav-menu'>
            <Link to='https://blog.code2trade.top' target='_blank'>About Us</Link>
            <Link to='https://blog.code2trade.top/service' target='_blank'>Coding Service</Link>
            {/* Dropdown cho Products */}
            <div
              className='dropdown'
              onMouseEnter={toggleProductsDropdown}
              onMouseLeave={closeProductsDropdown}
            >
              <span className='dropdown-toggle'>Products</span>
              {isProductsDropdownOpen && (
                <div className='dropdown-menu'>
                  <Link to='https://chromewebstore.google.com/detail/tradingview-strategy-test/dgdnhbipkmnagblmljidilgbcjdphjmi' target='_blank'>Strategy Tester</Link>
                  <Link to='https://blog.code2trade.top/category/indicators/' target='_blank'>Indicators</Link>
                  <Link to='https://blog.code2trade.top/category/tradingbot/' target='_blank'>Trading Bots</Link>
                </div>
              )}
            </div>
            <Link to='https://blog.code2trade.top/?post_type=post' target='_blank'>Blog</Link>
            <Link to='https://blog.code2trade.top/#contact' target='_blank'>Contact</Link>
          </nav>
        </div>
        <div className='user-info'>
          <p>Hello, {user?.username}!</p>
          <p id='logout' onClick={logoutUser}>
            Logout
          </p>
        </div>
      </div>
      {/* Sidebar nav menu cho mobile */}
      <nav className={`sidebar-nav-menu ${isSidebarOpen ? 'open' : ''}`}>
        <div className='close-icon' onClick={closeSidebar}>
          &times; {/* Biểu tượng đóng */}
        </div>
        <Link to='https://blog.code2trade.top' target='_blank'>About Us</Link>
        <Link to='https://blog.code2trade.top/service' target='_blank'>Coding Service</Link>
        {/* Dropdown cho Products trong sidebar */}
        <div className='sidebar-dropdown'>
          <span onClick={toggleProductsDropdown}>Products</span>
          {isProductsDropdownOpen && (
            <div className='sidebar-dropdown-menu'>
              <Link to='https://chromewebstore.google.com/detail/tradingview-strategy-test/dgdnhbipkmnagblmljidilgbcjdphjmi' target='_blank'>Strategy Tester</Link>
              <Link to='https://blog.code2trade.top/category/indicators/' target='_blank'>Indicators</Link>
              <Link to='https://blog.code2trade.top/category/tradingbot/' target='_blank'>Trading Bots</Link>
            </div>
          )}
        </div>
        <Link to='https://blog.code2trade.top/#blog' target='_blank'>Blog</Link>
        <Link to='https://blog.code2trade.top/#contact' target='_blank'>Contact</Link>
      </nav>
    </div>
  ) : null;
};

export default Header;
